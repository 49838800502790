.header {
	padding: 23px 40px 24px 40px;
}

.headerCont {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 53px;
}

.headerCont .logo {
	flex-grow: 1;		
}

.headerCont .logo img{
	width: 230px;
	height: 53px;
}

.headerCont .navItem {
	flex-grow: 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.headerCont .navItem .requestBtn {
	background: #9EC05B;
	color: #FFFFFF;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	letter-spacing: 0px;
	text-align: center;
	text-transform: capitalize;
	height: 48px;
	width: 172px;
	border-radius: 2px;
}

.headerCont .navItem .divider{
	width: 1px;
	height: 40px;
	background-color: #919295;
	margin: 0 44px 0 39px;
}

.headerCont .navItem svg {
	width: 32px;
	height: 32px;
}

.closeIcon .closeBtn {
	cursor: pointer;
}

@media (max-width: 500px) {
    .headerCont .navItem .requestBtn {
		display: none;
	}
	.headerCont .navItem .divider {
		display: none;
	}
}

@media (min-width: 576px && max-width:  767px) {
    
}

@media (max-width: 767px) {
	.header {
		padding:  10px;
	}
	.headerCont {
		height: 45px;
	}
	.headerCont .logo img{
		width: 130px;
		height: 45px;
	}
	.makeStyles-menuButton-1 {
		margin: 0 !important;
	}
	.headerCont .navItem .divider {
		margin: 0 0 0 10px;
	}
	.headerCont .navItem .requestBtn {
		height: 35px;
    	width: 150px;
    	font-size: 14px;
	}
}

@media (max-width: 992px) {

}
