.banner {
  position: relative;
  width: 100%;  
  height: 425px;
  background: #000000B2;
}

.bannerImg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: url('./assets/banner.jpg');  
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;
  z-index: -1;
}

.content {
  position: absolute;
  top: 23%;
  left: 12%;
  max-width: 515px;
  color: #FFFFFF;
}

.content .heading {
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: 0px;
  text-align: left;
}

.content .desc {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin: 15px 0;
}

.content .requestBtn {
  background: #9EC05B;
  color: #FFFFFF;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  text-transform: capitalize;
  height: 48px;
  width: 172px;
  border-radius: 2px;
}

.content .requestBtn:hover {
  background: #9EC05B;
  box-shadow: none;
}

.mainContent {
  margin-top: 7%;
  padding: 0 12%;
}

.mainContent .heading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 46px;
  text-align: center;
  color: #3B556B
}

.mainContent .desc {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #565759;
  margin: 26px 0 60px 0;
}

.colContent {
  padding: 0 6%;
  margin-bottom: 70px;
}

.colContent h1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #3B556B;
  margin:0 auto;
}

.colContent .desc {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #565759;
  margin: 10px auto;
}

.learnMoreContent {
  background: #7A9C48;
}

.learnMoreContent .pad160 {
  padding: 47px 12%;
}

.learnMoreContent .learnText{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.learnMoreContent .learnCTA {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  color: #FFFFFF;
}

.learnMoreContent .learnCTA > span {
  border: 1px solid #FFFFFF;
  border-radius: 2px;
  padding: 15px 24px;
  cursor: pointer;
}

footer {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #3B556B;
  padding: 34px 0 26px 40px;
}

@media (max-width: 767px) {
  .content {
    top: 20%;
    left: 8%;
    max-width: 400px;
  }
  .content .heading {
    font-size: 40px;
    line-height: 40px;
  }
  .mainContent {
    margin-top: 7%;
    padding: 0 5%;
  }

  .mainContent .heading {
    font-size: 30px;
    line-height: 36px;
  }

  .mainContent .desc {
    font-size: 14px;
    line-height: 22px;
    margin: 20px 0 40px 0;
  }

  .colContent .desc {
    font-size: 14px;
    line-height: 22px;  
  }

  .learnMoreContent .learnCTA {
    margin-top: 20px;
  }

  .learnMoreContent .learnCTA > span {
    padding: 10px;
  }

  .learnMoreContent .learnText {
    text-align: center;
  }

  .learnMoreContent .learnCTA {
    justify-content: center;
  }

}

@media (max-width: 500px) {
  .content {
    max-width: 350px;
  }
}

@media (max-width: 992px) {
  .mainContent {
    margin-top: 7%;
    padding: 0 8%;
  }

  .learnMoreContent .pad160 {
    padding: 47px 5%;
  }

  .colContent .col {
    margin-bottom: 15px;
  }
}

@media (max-width: 1200px) {
  .learnMoreContent .pad160 {
    padding: 47px 10%;
  }  
}