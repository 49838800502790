.modalBody {
	padding: 0 25px;
}

.modalBody .desc {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #565759;
	margin-top: 30px;
}

.formCont {
	margin-top: 25px;
}

.full-wd {
	width: 100%;
	width: 100%;
    position: relative !important;
    padding-bottom: 15px !important;
}

.full-msg-wd {
	width: 100%;
	width: 100%;
    position: relative !important;
    padding-bottom: 18px !important;
}

.full-wd .MuiInputBase-root {
	 height: 52px; 
}

.MuiFormHelperText-root.Mui-error {
	position: absolute;
    bottom: 0;
    margin: 0;
    font-size: 11px;
}

.fullTA {
	width: 100%;
	padding: 10px 14px;
}

.rdCta .requestBtn {
  background: #9EC05B;
  color: #FFFFFF;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  text-transform: capitalize;
  height: 58px;
  width: 100%;
  border-radius: 2px;
  box-shadow: none;
}

.rdCta {
	position: relative;
	padding-bottom: 20px;
}

.rdCta .errorMsg {
	position: absolute;
	bottom: 0;
	font-size: 11px;
	color:  red;
}
.rdCta .requestBtn:hover {
  background: #9EC05B;
  box-shadow: none;
}

.succCont {
    width: 50%;
    text-align: center;
    margin: 0px auto;
}